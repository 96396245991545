import './App.css';
import 'react-phone-number-input/style.css'
import React,{useState,useEffect} from 'react';
import Header from "../../Components/Header";
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import useGeoLocation from "react-ipgeolocation";
import {i18n} from '../../Translate/i18n';

function Home() {
    const [id, setId] = useState("");
    const location = useGeoLocation();
    const I18N_STORAGE_KEY = 'i18nextLng'
    

  

   useEffect(()=>{
    function selectLanguagem(){
      localStorage.setItem(
        I18N_STORAGE_KEY,
        location.country
      )
    }

    selectLanguagem()
    
   })

  return (
    <div>
    




      <div className='boxinfo'>
     
       <div className='bxtextos'>
       <div className='textit'> <h1>Sua compra foi aprovada!</h1></div>

       <div> <h2>Você poderá acessar essa plataforma usando o link enviado em seu email </h2></div>

       </div>
       
       <div className='bxbemvindo'>
<h1>Bem vinda(o)!</h1>
<span>Vamos precisar de algumas informações suas novamente:</span>
       </div>

        <span>Seu usuário no Instagram:</span>
        <input onChange={(e)=>setId(e.target.value)} placeholder='Sem espaços ou @'/>
        <span>Digite sem o "@",exemplo: "cintya"</span>
        <Link to={`/perfil/${id}`}>Continuar</Link>
      </div>
  
    </div>
  );
}

export default Home;
