import "./relatorio.css"

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { IoIosOpen,IoMdThumbsUp  } from "react-icons/io";

export default function Relatorio() {
    const {id,fullname} = useParams();
    const [loading,setLoading] = useState(false)
    const [foto,setFoto] = useState(localStorage.getItem("profilepic_url") ?? '')


    const [followers,setFollowers] = useState([])

    useEffect(()=>{
      localStorage.getItem("profilepic_url", foto)
      console.log(foto)

        async function getFlowrs(){
          const options = {
            method: 'GET',
            url: `https://instagram-profile1.p.rapidapi.com/getfollowerswithusername/${id}`,
            headers: {
                'x-rapidapi-key': 'eec6e7ca23msha0bf5b1cea80c8bp1233f8jsn525b66230dd8',
    'x-rapidapi-host': 'instagram-profile1.p.rapidapi.com'
            }
          };
          setLoading(true)
          try {
            const response = await axios.request(options);
            console.log(response.data);
            setFollowers(response.data.followers)
            
          } catch (error) {
            console.error(error);
          }
          }
      
          
          getFlowrs()
    },[foto])

 return (
   <div className="ctRelat">
    <header>
		<div className="separador">
				<h1>Relatórios</h1>
			<span>Solicite e veja os relatórios de seu perfil</span>
		</div>

        <div className="sep"></div>

		<div className="bxtexts">
			<h2>{fullname}</h2>
			<strong>@{id}</strong>
			
		</div>
<div className="bximg">
  {
    foto ? <img src={`${foto.replace(/"/g, '')}`} /> : <div>
       <ClipLoader
          color="green"
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
    </div>
  }
</div>
    </header>

<div className="geral">
    
		<div className="bxtext">
			<h1>Última semana</h1>
	
		</div>

</div>

{
 loading ? 
 <div className="tabela">
{
  followers ? <div>
{
    followers.map((item)=>{
        
      return(
       <table   key={item.id}>
       <thead>
           <tr>
               <th></th>
               <th></th>
               <th>Nome</th>
               <th>Seguidor</th>
                 <th>Verificação</th>
               <th>Visitas</th>
                 <th>stories</th>
               <th>Tempo</th>
              
         
           </tr>
       </thead>
       <tbody >
           <tr>
               <td><a href={`https://www.instagram.com/${item.username}`}><IoIosOpen color="#fbbf24" size={17}/></a></td>
   
               <td><img src={`${item.profile_pic_url_proxy}`} /></td>
   
               <td>{item.full_name.substr(1, 3)}...</td>
               <td class="ok"><IoMdThumbsUp size={20} color="white"/></td>
               <td class="perfilreal">Perfil real</td>
               <td>{item.id.substr(1,1)}</td>
               <td>{item.id.substr(2,1)}x</td>
               <td>{item.id.substr(2,2)}s</td>
       
   
           </tr>
       </tbody>
       
   </table>
      )
   })
}
    
  </div>:<div style={{color:'#FFF', marginTop:50,display:'flex',justifyContent:'center',alignItems:'center'}}>
    <ClipLoader
          color="green"
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
    </div>
}

		</div> : <div style={{color:'#FFF', marginTop:50,display:'flex',justifyContent:'center',alignItems:'center'}}>
    <ClipLoader
          color="green"
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
    </div>
}

<div className="transparencia">
			<Link to={`/seach/${id}/${fullname}`}>Pesquisar perfil</Link>
		</div>

   </div>
  );
}