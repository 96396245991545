const messages = {

    BR:{
        translations:{
            titles:{
                app:'Localizador de celular',
                menBox:'Parabéns',
                titulo:'Acesso Bloqueado!',
                tituloseq:'Como faço para ter acesso ao celular da pessoa que está sendo localizado(a)?',
                seq1:'1 - é necessário pagar uma taxa, referente ao serviço e aplicativo que será disponibilizado.',
                seq2:'2 - Após o pagamento será enviada a aplicação diretamente em seu e-mail.',
                seq3:'3 - Siga o mesmo procedimento feito no site.',
                seq4:'4 - insira o número da pessoa e aguarde até o sistema finalizar o acesso ao celular.',
                tax1:'Para usar o ViewerSpy e conectar no celular',
                tax2:', exigimos uma pequena contribuição simbolica de R$19,87.',
               
                
                tit:'carregando...'

            },
            messages:{
                inputtext:'Exemplo: (00) 00000-0000',
                itWorks:'Digite o número para localizar',
                itMen:'Celular localizado com sucesso!',
                boxmensagem:'Para acessar o celular',
                contmensagem:', Clique em "CONTINUAR" para entender o funcionamento do ViewerSpy.'
            },
            buttons:{
                save:'Rastrear celular',
                btn:'CONTINUAR',
                taxa:'PAGAR TAXA',
                btnclicou:'Pressione aqui'
            },
            boxpainel:{
                acesso:'Conectando ao painel',
                info:'Informações do celular',
                loc:'Localizando celular',

            }


        }
    }


    

}

export {messages}