import "./seach.css"
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { IoIosOpen,IoMdThumbsUp,IoMdArrowRoundBack  } from "react-icons/io";

export default function Seach() {
    const [loading,setLoading] = useState(true)
    const {id,fullname} = useParams();
    const [foto,setFoto] = useState(localStorage.getItem("profilepic_url") ?? '')
    const [btn,setBtn] = useState(true)
    const [dados,setDados] = useState([''])
    const [idseach,setIdseach] = useState('')

    useEffect(()=>{
if(btn == false){
  datas()
}

async function datas() {
    const options = {
        method: 'GET',
        url: `https://instagram-profile1.p.rapidapi.com/searchuser/${idseach}`,
        headers: {
          'x-rapidapi-key': 'eec6e7ca23msha0bf5b1cea80c8bp1233f8jsn525b66230dd8',
          'x-rapidapi-host': 'instagram-profile1.p.rapidapi.com'
        },
    
      };
      
      try {
          const response = await axios.request(options);
          console.log(response.data);
          setDados(response.data.result)
          setLoading(false)
      } catch (error) {
          console.error(error);
      }
}
    },[btn])

   async function muda(){
        setBtn(false)

       
    }

 return (
  <div className="Ctnseach">
     
{
    loading ? 
    
    <div className="pesquisa">
      <a><IoMdArrowRoundBack color="#FFF" size={30}/></a>
        
        <header>
		<div className="separador">
				<h1>Pesquisa</h1>
			<span>Relátorio individual de um usuário específico</span>
		</div>

        <div className="sep"></div>

		<div className="bxtexts">
			<h2>{fullname}</h2>
			<strong>@{id}</strong>
			
		</div>
<div className="bximg">
  {
    foto ? <img src={`${foto.replace(/"/g, '')}`} /> : <div>
       <ClipLoader
          color="green"
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
    </div>
  }
</div>
    </header>

   <div className="bxipnuts">
   <h2>Usuário alvo:</h2>
   <input onChange={(e)=>setIdseach(e.target.value)} placeholder="Sem espaços nem @"/>
   <h3>Digite sem o "@", exemplo: cristianosilva</h3>

   <button onClick={muda}>{
    btn ? <span>Continuar</span> :  <ClipLoader
    color="black"
      size={25}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
    }</button>

   </div>
        </div> : 
        
        <div className="bxipnuts">

<Link to={`/relatorio/${id}/${fullname}`} ><IoMdArrowRoundBack color="#FFF" size={30}/></Link>
        
        <header>
		<div className="separador">
				<h1>Resultados</h1>
			<span>Exibindo dados de @{idseach}</span>
		</div>

        <div className="sep"></div>

		<div className="bxtexts">
			<h2>{fullname}</h2>
			<strong>@{id}</strong>
			
		</div>
<div className="bximg">
  {
    foto ? <img src={`${foto.replace(/"/g, '')}`} /> : <div>
       <ClipLoader
          color="green"
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
    </div>
  }
</div>
    </header>


   
 <div className="tabela">
{
  dados ? <div>
{
    dados.map((item)=>{
        
      return(
       <table   key={item.id}>
       <thead>
           <tr>
               <th></th>
               <th></th>
               <th>Nome</th>
               <th>Seguidor</th>
                 <th>Verificação</th>
               <th>Visitas</th>
                 <th>stories</th>
               <th>Tempo</th>
              
         
           </tr>
       </thead>
       <tbody >
           <tr>
               <td><a href={`https://www.instagram.com/${item.username}`}><IoIosOpen color="#fbbf24" size={17}/></a></td>
   
               <td><img src={`${item.profile_pic_url_proxy}`} /></td>
   
               <td>{item.full_name.substr(1, 3)}...</td>
               <td class="ok"><IoMdThumbsUp size={20} color="white"/></td>
               <td class="perfilreal">Perfil real</td>
               <td>{item.id.substr(1,1)}</td>
               <td>{item.id.substr(2,1)}x</td>
               <td>{item.id.substr(2,2)}s</td>
       
   
           </tr>
       </tbody>
       
   </table>
      )
   })
}
    
  </div>:<div style={{color:'#FFF', marginTop:50,display:'flex',justifyContent:'center',alignItems:'center'}}>
    <ClipLoader
          color="green"
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
    </div>
}

		</div> 

    
        </div>
}
  </div>
  );
}