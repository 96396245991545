const messages = {

    ES:{
        translations:{
            titles:{
                app:'Localizador de celular',
                menBox:'Felicidades',
                titulo:'Acesso Bloqueado!',
                tituloseq:'¿Cómo accedo al celular de la persona que está siendo localizada?',
                seq1:'1 - Es necesario pagar una tarifa por el servicio y la aplicación que estarán disponibles.',
                seq2:'2 - Después del pago, la solicitud se enviará directamente a su correo electrónico.',
                seq3:'3 - Siga el mismo procedimiento realizado en el sitio web.',
                seq4:'4 - Ingrese el número de la persona y espere a que el sistema complete el acceso al teléfono celular.',
                tax1:'Para usar ViewerSpy y conectarse en su celular',
                tax2:', requerimos un pequeño aporte simbólico de US$ 19,87.',
                tit:'cargando...'

            },
            messages:{
                inputtext:'Ejemplo: (00) 00000-0000',
                itWorks:'Introduce el número para localizar',
                itMen:'¡Celular localizado exitosamente!',
                boxmensagem:'Para acceder al celular',
                contmensagem:', Haga clic en "CONTINUAR" para comprender cómo funciona ViewerSpy.'
            },
            buttons:{
                save:'Rastrear celular',
                btn:'CONTINUAR',
                taxa:'PAGAR TAXA',
                btnclicou:'Pressione aqui'
            },
            boxpainel:{
                acesso:'Conexión al panel',
                info:'Información móvil',
                loc:'Localizando telephone',

            }


        }
    }


    

}

export {messages}