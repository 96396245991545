import { Route, Routes,BrowserRouter } from "react-router-dom"
import Home from "./Pages/Home";
import Perfil from "./Pages/perfil"
import Relatorio from "./Pages/Relatorio";
import Seach from './Pages/Seach'


function App() {
  return (
   <BrowserRouter>
    <Routes>

<Route path="/"   element = {<Home/> } />

<Route path="/perfil/:id"   element = { <Perfil/> } />
<Route path="/relatorio/:id/:fullname"   element = { <Relatorio/> } />
<Route path="/seach/:id/:fullname"   element = { <Seach/> } />


   </Routes>
   </BrowserRouter>
  );
}

export default App;
